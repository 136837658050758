import { StaticImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import { variantPages } from "../animations/pageTransition";
import React from "react";
import MapRoute from "../components/mapRoute";
import Feedback from "../components/service/feedback";
import MetaTag from "../components/metaTag";
import Footer from "../components/footer";
import { indoortowtruckSEO } from "../data/seo";
import { Directions } from "../components/svg/services";
import {
  IoPersonOutline,
  IoTimeOutline,
  IoDocumentTextOutline,
  IoCartOutline,
} from "react-icons/io5";
import cx from "classnames";
import * as styles from "../styles/pages/services.module.scss";

function Indoortowtruck() {
  return (
    <motion.main
      variants={variantPages}
      initial="initial"
      exit="exit"
      animate="animate"
    >
      <MetaTag data={indoortowtruckSEO} />
      <section className={styles.container}>
        <div className={styles.title}>
          <h1>Крытый эвакуатор</h1>
        </div>

        <div className={styles.description}>
          <div className={styles.header}>
            <h3>Эвакуатор с крытым тентом</h3>
            <p className={styles.duration}>от 2 до 10 рабочих дней</p>
          </div>
          <div className={styles.content}>
            <div className={styles.preview}>
              <StaticImage
                src="../images/services/6.jpg"
                alt="Крытый эвакуатор"
              />
            </div>
            <div className={styles.info}>
              <p>
                Крытый эвакуатор – это безупречная перевозка автомобилей,
                мотоциклов и ценного груза с помощью специального транспорта с
                тентом. Крытый эвакуатор абсолютно безопасен и надёжен.
              </p>
            </div>
          </div>
        </div>

        <MapRoute />

        <div className={styles.about}>
          <div className={styles.block}>
            <h3>Кто заказывает?</h3>
            <p className={styles.text}>
              Чаще всего крытый эвакуатор закатывают для перевозки автомобилей
              «премиум» или «бизнес» класса, спорткаров, ретро-автомобилей и
              коллекционных авто. Но бывают и другие клиенты у которых:
            </p>
            <ul className={styles.list}>
              <li>
                Автомобиль не на ходу и сильно повреждён (заблокированы колеса
                или руль, после ДТП)
              </li>
              <li>Грузовые автомобили</li>
              <li>Прицепы и полуприцепы</li>
              <li>ТС специального назначения</li>
              <li>Водные виды транспорта – катера, яхты и пр.</li>
            </ul>
            <p className={styles.text}>
              При перевозке ценного груза или эксклюзивной модели авто мы
              гарантируем полную конфиденциальность.
            </p>

            <Directions className={styles.background} />
            <div className={styles.hint}>
              <IoPersonOutline />
            </div>
          </div>

          <div className={styles.block}>
            <h3>Вы получите</h3>
            <ul className={styles.list}>
              <li>Скорость и комфорт перевозки</li>
              <li>
                Возможность детального планирования маршрута в любую точку
                России
              </li>
              <li>
                Современный парк автовозов для перемещения машин с любыми
                габаритами
              </li>
              <li>Безопасность и секретность</li>
            </ul>
            <div className={styles.hint}>
              <IoCartOutline />
            </div>
          </div>

          <div className={styles.block}>
            <h3>Формирование сроков и цены</h3>
            <p className={styles.text}>
              <span>Как складывается цена:</span>
              <br />
              <br />
            </p>
            <ul className={styles.list}>
              <li>
                Габариты автомобиля. Все а/м мы распределили на несколько
                классов. Градация связана с возможностями автовоза
              </li>
              <li>Маршрут перевозки</li>
              <li>
                Доп. услуги доставки: доставка от «двери до двери»;
                дополнительное страхование груза; погрузка / выгрузка аварийных
                или страховых автомобилей
              </li>
            </ul>
            <p className={styles.text}>
              <br />
              <span>
                Сроки:
                <br />В среднем перевозка занимает от 3 до 10 рабочих дней в
                зависимости от направлений и сезонного фактора
              </span>
              <br />
              <br />
              Дополнительное время требуется на приемку и оформление
              сопроводительных документов и обработку груза по факту завершения
              перевозки
            </p>
            <div className={styles.hint}>
              <IoTimeOutline />
            </div>
          </div>

          <div className={styles.block}>
            <h3>Документы для перевозки</h3>
            <ul className={styles.list}>
              <li>Копии паспорта отправителя или заказчика</li>
              <li>
                Реквизитная карточка юридического лица и доверенность на
                представителя или печать организации
              </li>
              <li>
                Копия документа на автомобиль (ПТС или свидетельство о
                регистрации)
              </li>
              <li>
                Контактные данные отправителя и получателя (ФИО и телефоны для
                связи)
              </li>
              <li>Маршрут доставки</li>
              <li>Примерная рыночная стоимость автомобиля</li>
              <li>
                Адрес погрузки и выгрузки, в случае если автомобиль доставляется
                с адреса в адрес
              </li>
            </ul>
            <Directions className={styles.background} />
            <div className={styles.hint}>
              <IoDocumentTextOutline />
            </div>
          </div>

          <div className={cx(styles.block, styles.additional)}>
            <h3>
              Компания «Автовоз Логистик» предлагает индивидуальную перевозку
              24/7
              <br /> и бережное отношение к вашему автомобилю.
            </h3>
            <p className={styles.text}>
              Если транспорт без повреждений и на ходу, то он сам заезжает на
              платформу крытого эвакуатора по специально оборудованным сходням.
              Не беспокойтесь, заказав эвакуатор крытого типа, автомобиль будет
              защищен всю дорогу. Позвоните или оставьте заявку на сайте и наш
              менеджер рассчитает стоимость перевозки машины с учетом маршрута и
              её габаритов.{" "}
              <span>
                Доверьте нам самое ценное, а мы позаботимся о деталях
                квалифицированной.
              </span>
            </p>
          </div>
        </div>

        <Feedback />
      </section>
      <Footer />
    </motion.main>
  );
}

export default Indoortowtruck;
